import React from "react"
import { Link } from "gatsby"

class Product extends React.Component {
  render() {
    const { title, description_short, variants, featured_image } =
      this.props.data.node
    const { slug } = this.props.data.node.fields

    let price = variants[0].price

    return (
      <div className="uk-margin-bottom">
        <Link to={slug} className="product-link uk-link-reset uk-height-1-1">
          <div className="uk-card uk-card-hover uk-card-default uk-card-small uk-card-body uk-card-border uk-height-1-1">
            <div className="uk-padding-small uk-padding-remove-vertical">
              <p className="uk-text-bold uk-text-primary uk-margin-small-bottom">
                {title}
              </p>
              <p className="uk-text-small uk-text-muted uk-margin-small">
                {description_short}
              </p>
            </div>
            <div className="product__img">
              <img src={featured_image} alt={title} />
            </div>

            <p className="product-models product-link__options uk-margin-small uk-text-muted">
              <small>Available in {variants.length} models</small>
            </p>
            <p className="product-link__label">
              <small>
                Learn more <span uk-icon="icon: arrow-right; ratio: 1"></span>
              </small>
            </p>
          </div>
        </Link>
      </div>
    )
  }
}

export default Product
