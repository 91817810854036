import React from "react"
import { Link } from "gatsby"

// import Content from "../pages/static-pages/index.yaml"

class CategoryFooter extends React.Component {
  state = { sliderOptions: "" }

  componentDidMount = () => {
    if (typeof window !== "undefined") {
      var win = window,
        doc = document,
        docElem = doc.documentElement,
        body = doc.getElementsByTagName("body")[0],
        x = win.innerWidth || docElem.clientWidth || body.clientWidth

      if (x >= 640) {
        this.setState({
          sliderOptions:
            "draggable: true; sets: true; autoplay: true; autoplayInterval: 3000;",
        })
      } else {
        this.setState({
          sliderOptions:
            "center: true;infinite: true; autoplay: true; autoplayInterval: 3000;",
        })
      }
    }
  }

  render() {
    const pageBreadcrumbs = "/products" + this.props.url[0].path
    const content = this.props.data.filter(
      (item) => item.link.url !== pageBreadcrumbs
    )
    const title = this.props.title

    return (
      <div className="uk-section uk-section-small uk-margin-small uk-margin-small-top uk-text-left">
        <h2 className="uk-text-center ">{title}</h2>
        <div
          className="uk-container uk-margin-large-top"
          tabIndex="-1"
          uk-slider={this.state.sliderOptions}
        >
          <div className="uk-slider-items image-slider">
            {content.map((item, i) => (
              <a
                href={item.link.url}
                key={i}
                className="uk-padding-small uk-width-1-3 uk-width-1-2@s uk-width-1-3@m"
              >
                <img
                  className="uk-flex uk-flex-center uk-margin-small uk-margin-bottom uk-text-primary"
                  src={item.image.url}
                  alt={item.title}
                />
                <h5 className="uk-text-primary uk-width-1-1 uk-width-1-1@m uk-margin-small-top">
                  {item.title}
                </h5>
              </a>
            ))}
          </div>
          <ul className="uk-slider-nav uk-dotnav uk-margin uk-flex-center uk-width-1-1"></ul>
        </div>
      </div>
    )
  }
}

export default CategoryFooter
